import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import { CurrentEnvironment, ICurrentEnvironment } from '@/config/environment';
export interface IAppState {
  environment: any;
  miniVariant: boolean;
}

@Module({ name: 'app' })
export default class extends VuexModule implements IAppState {
  //TODO: base on base url
  private env: ICurrentEnvironment = new CurrentEnvironment() as ICurrentEnvironment;
  public environment: any = { name: this.env.name };
  public miniVariant: boolean = false;

  @Action
  toggleSidebarVariant() {
    this.context.commit('SET_MINI_VARIANT');
  }

  @Mutation
  SET_MINI_VARIANT() {
    this.miniVariant = !this.miniVariant;
  }
}
