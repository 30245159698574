
import Vue from 'vue';
import Component from 'vue-class-component';
import AppModule from '@/store/modules/app';
import { getModule } from 'vuex-module-decorators';
import sidebar from './sidebar';
import { Prop } from 'vue-property-decorator';

let reports: any;

@Component({
  name: 'Sidebar',
})
export default class Sidebar extends Vue {
  public appModule = getModule(AppModule, this.$store);

  public showFinanceReports: boolean = true;
  public showMarketingReports: boolean = true;
  public showOperationsReports: boolean = true;
  public showTurnstileReports: boolean = true;

  public financeReports: any;
  public marketingReports: any;
  public operationsReports: any;
  public turnstileReports: any;

  async created() {
    let profileJson: any;

    let reports: any = sidebar.admin_reports;

    this.financeReports = reports.financeReports;

    this.marketingReports = reports.marketingReports;

    this.operationsReports = reports.operationsReports;

    this.turnstileReports = reports.turnstileReports;

    this.showFinanceReports = true;
    this.showMarketingReports = true;
    this.showOperationsReports = true;
    this.showTurnstileReports = true;
  }

  async updated() {
    let profileJson: any;

    let reports: any = sidebar.admin_reports;

    this.financeReports = reports.financeReports;

    this.marketingReports = reports.marketingReports;

    this.operationsReports = reports.operationsReports;

    this.turnstileReports = reports.turnstileReports;

    this.showFinanceReports = true;
    this.showMarketingReports = true;
    this.showOperationsReports = true;
    this.showTurnstileReports = true;
  }
}
