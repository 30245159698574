import * as auth0 from 'auth0-js';
import router from '@/router';
import { Auth0Config, IAuth0Config } from '@/config/Auth0Config';
import { CurrentEnvironment, ICurrentEnvironment } from '@/config/environment';

export class Auth0Service {
  private env: ICurrentEnvironment = new CurrentEnvironment() as ICurrentEnvironment;
  private config: IAuth0Config = new Auth0Config() as IAuth0Config;
  // Create Auth0 web auth instance
  auth0 = new auth0.WebAuth({
    clientID: this.config.clientId,
    domain: this.config.domain,
    responseType: 'token',
    redirectUri: this.env.callbackUrl,
    audience: 'https://space-needle.us.auth0.com/api/v2/',
    scope:
      'read:users read:user edit:user read:current_user openid offline_access',
  });
  // Store authentication data

  expiresAt: number;
  userProfile: any;
  accessToken: string;
  authenticated: boolean;

  private static instance: Auth0Service;
  private constructor() {
    this.getAccessToken();
    this.expiresAt = 50;
    this.accessToken = '';
    this.authenticated = false;
  }

  static getInstance() {
    if (!Auth0Service.instance) {
      Auth0Service.instance = new Auth0Service();
    }
    return Auth0Service.instance;
  }

  async login() {
    // Auth0 authorize request
    try {
      await this.auth0.authorize();
    } catch (Error) {
      sessionStorage.setItem('IsLoggedIn', 'false');
    }
  }

  handleLoginCallback() {
    // When Auth0 hash parsed, get profile
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken) {
        this.getUserInfo(authResult);
      }
    });
  }

  getAccessToken() {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken) {
        this.getUserInfo(authResult);
      }
    });
  }

  getUserInfo(authResult: any) {
    // Use access token to retrieve user's profile and set session
    this.auth0.client.userInfo(authResult.accessToken, (err, profile) => {
      if (profile) {
        let user = JSON.stringify(profile);
        sessionStorage.setItem('IsLoggedIn', 'true');
        sessionStorage.setItem('profile', user);
        router.push('/').catch(() => {});
        this._setSession(authResult, profile);
      }
    });
  }

  private _setSession(authResult: any, profile: any) {
    // Save authentication data and update login status subject
    this.expiresAt = authResult.expiresIn * 1000 + Date.now();
    this.accessToken = authResult.accessToken;
    sessionStorage.setItem('accessToken', authResult.accessToken);
    sessionStorage.setItem('role', profile['https://guestx.com/roles'][0]);
    this.userProfile = profile;
    this.authenticated = true;
  }

  logout() {
    // Log out of Auth0 session
    // Ensure that returnTo URL is specified in Auth0
    // Application settings for Allowed Logout URLs
    this.auth0.logout({
      returnTo: `${window.location.origin}`,
      clientID: this.config.clientId,
    });
  }

  get isLoggedIn(): boolean {
    // Check if current date is before token
    // expiration and user is signed in locally
    return Date.now() < this.expiresAt && this.authenticated;
  }
}
