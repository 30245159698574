export interface ICurrentEnvironment {
  name: string;
  parambaseUrl: string;
  functionAppbaseUrl: string;
  callbackUrl?: string;
}

export class CurrentEnvironment implements ICurrentEnvironment {
  public name: string = '';
  public parambaseUrl: string = '';
  public diamondUrl: string = '';
  public authUri: string = '';
  public functionAppbaseUrl: string = '';
  public callbackUrl: string = '';

  constructor() {
    this.callbackUrl = window.location.origin + '/callback';

    this.name = process.env.VUE_APP_NAME;
    this.parambaseUrl = process.env.VUE_APP_PARAMBASE_URL;
    this.functionAppbaseUrl = process.env.VUE_APP_FUNCTION_APPBASE_URL;
  }
}
