import Vue from 'vue';
import Axios from 'axios';
import VueAxios from 'vue-axios';

import { CurrentEnvironment, ICurrentEnvironment } from '@/config/environment';

export class AxiosClient {
  private env: ICurrentEnvironment = new CurrentEnvironment() as ICurrentEnvironment;
  constructor() {
    Vue.use(VueAxios, Axios);
    Vue.axios.defaults.baseURL = this.env.parambaseUrl;
  }

  get(resource: string, slug: string = '', params: any): Promise<any> {
    return Vue.axios.get(`${resource}/${slug}`, { ...params });
  }

  query(resource: string, params: any): Promise<any> {
    return Vue.axios.get(`${resource}`, params);
  }

  post(resource: string, params: any, config?: any): Promise<any> {
    return Vue.axios.post(`${resource}`, params, config);
  }
  posts(resource: string, params: any, config: any): Promise<any> {
    return Vue.axios.post(`${resource}`, params, config);
  }

  put(resource: string, params: any): Promise<any> {
    return Vue.axios.put(`${resource}`, params);
  }

  delete(resource: string, params: any): Promise<any> {
    return Vue.axios.delete(`${resource}`, params);
  }
}
