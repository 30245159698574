import Vue from 'vue';
import VueRouter from 'vue-router';
import Shell from '@/layout/Shell.vue';
import { authGuard } from '@/views/auth0/authGuard';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: Shell,
        redirect: '/home',
        children: [
            {
                path: 'home',
                component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
                name: 'Home',
                //meta: {},
                beforeEnter: authGuard
            },
            {
                path: 'reports/finance/sales-summary',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/finance/sales-summary/SalesSummary.vue'),
                name: 'Sales Summary',
                // meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/finance/sales-detail',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/finance/sales-detail/SalesDetail.vue'),
                name: 'Sales Detail',
                // meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/finance/sales-redeemed-summary',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/finance/sales-redeemed-summary/SalesRedeemedSummary.vue'),
                name: 'Sales Redeemed Summary',
                //meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/finance/sales-redeemed-detail',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/finance/sales-redeemed-detail/SalesRedeemedDetail.vue'),
                name: 'Sales Redeemed Detail',
                //meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/finance/tender-summary',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/finance/tender-summary/TenderSummary.vue'),
                name: 'Tender Summary',
                //meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/finance/tender-detail',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/finance/tender-detail/TenderDetail.vue'),
                name: 'Tender Detail',
                //meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/finance/city-pass-detail',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/finance/city-pass-detail/CityPassDetail.vue'),
                name: 'City Pass Detail',
                //meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/finance/kiosk-upgrade-detail',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/finance/kiosk-upgrade-detail/KioskUpgradeDetail.vue'),
                name: 'Kiosk Upgrade Detail',
                //meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/finance/Taxes-Due-Details',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/finance/Taxes-Due-Details/TaxesDueDetails.vue'),
                name: 'Taxes Due Detail',
                //meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/finance/taxes-due-summary',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/finance/taxes-due-summary/TaxesDueSummary.vue'),
                name: 'Taxes Due Summary',
                // meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/finance/voucher-receivables-detail',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/finance/voucher-receivables-detail/voucherReceivablesDetail.vue'),
                name: 'Voucher Receivables Detail',
                // meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/finance/voucher-receivables-summary',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/finance/voucher-receivables-summary/voucherReceivablesSummary.vue'),
                name: 'Voucher Receivables Summary',
                //   meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/finance/promotions-redeemed-detail',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/finance/promotions-redeemed-detail/PromotionsRedeemedDetail.vue'),
                name: 'Promotions Redeemed Detail',
                // meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/finance/promotions-redeemed-summary',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/finance/promotions-redeemed-summary/PromotionsRedeemedSummary.vue'),
                name: 'Promotions Redeemed Summary',
                //meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/finance/sales-not-redeemed-detail',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/finance/sales-not-redeemed-detail/SalesNotRedeemedDetail.vue'),
                name: 'Sales Not Redeemed Detail',
                //meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/finance/sales-not-redeemed-summary',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/finance/sales-not-redeemed-summary/SalesNotRedeemedSummary.vue'),
                name: 'Sales Not Redeemed Summary',
                //meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/finance/receivables-due-detail',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/finance/receivables-due-detail/ReceivableDueDetail.vue'),
                name: 'Receivable Due Detail',
                //meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/finance/receivables-due-summary',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/finance/receivables-due-summary/ReceivableDueSummary.vue'),
                name: 'Receivable Due Summary',
                //meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/finance/payable-due-detail',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/finance/payable-due-detail/PayableDueDetail.vue'),
                name: 'Payable Due Detail',
                //meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/finance/payable-due-summary',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/finance/payable-due-summary/PayableDueSummary.vue'),
                name: 'Payable Due Summary',
                //meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/finance/promotions-sold-detail',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/finance/promotions-sold-detail/PromotionsSoldDetail.vue'),
                name: 'Promotions Sold Detail',
                //meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/finance/promotions-sold-summary',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/finance/promotions-sold-summary/PromotionsSoldSummary.vue'),
                name: 'Promotions Sold Summary',
                // meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            //{
            //  path: "reports/finance/category-sales-detail",
            // component: () => import(/* webpackChunkName: "reports" */ "@/views/reports/finance/category-sales-detail/CategorySalesDetail.vue"),
            // name: "Category Sales Detail",
            // meta: { authorize: ["Admin", "Finance"] },
            // beforeEnter: authGuard
            //},
            {
                path: 'reports/finance/transactions-detail-by-ticket',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/finance/transactions-detail-by-ticket/TransactionsDetailByTicket.vue'),
                name: 'Transactions Detail By Ticket',
                //meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/marketing/guest-contact-info',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/marketing/guest-contact-info/GuestContactInfo.vue'),
                name: 'Guest Contact Info',
                //meta: { authorize: ["Admin", "Finance", "Supervisor"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/marketing/manifest',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/marketing/manifest/Manifest.vue'),
                name: 'Manifest',
                //meta: { authorize: ["Admin", "Finance", "Supervisor", "Agent", "Group Sales"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/operations/access-summary',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/operations/access-summary/AccessSummary.vue'),
                name: 'Access Summary',
                // meta: { authorize: ["Admin", "Finance", "Supervisor"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/operations/bankout-tender-summary',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/operations/bankout-tender-summary/BankoutTenderSummary.vue'),
                name: 'Bankout Tender Summary',
                // meta: { authorize: ["Admin", "Finance", "Supervisor", "Agent", "Group Sales"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/operations/bankout-tender-detail',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/operations/bankout-tender-detail/BankoutTenderDetail.vue'),
                name: 'Bankout Tender Detail',
                // meta: { authorize: ["Admin", "Finance", "Supervisor", "Agent", "Group Sales"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/operations/inventory-calendar',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/operations/inventory-calendar/InventoryCalendar.vue'),
                name: 'Inventory Calendar',
                //meta: { authorize: ["Admin", "Finance", "Supervisor"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/operations/advance-sales-detail',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/operations/advance-sales-detail/AdvanceSalesDetail.vue'),
                name: 'Advance Sales Detail',
                //meta: { authorize: ["Admin", "Finance", "Supervisor"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/operations/advance-sales-summary',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/operations/advance-sales-summary/AdvanceSalesSummary.vue'),
                name: 'Advance Sales Summary',
                //meta: { authorize: ["Admin", "Finance", "Supervisor"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/operations/redemption-detail',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/operations/redemption-detail/RedemptionDetail.vue'),
                name: 'Redemption Detail',
                //meta: { authorize: ["Admin", "Finance", "Supervisor"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/operations/redemption-summary',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/operations/redemption-summary/RedemptionSummary.vue'),
                name: 'Redemption Summary',
                //meta: { authorize: ["Admin", "Finance", "Supervisor"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/operations/validation-experience-summary',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/operations/validation-experience-summary/ValidationExperienceSummary.vue'),
                name: 'Validation Experience Summary',
                //meta: { authorize: ["Admin", "Finance", "Supervisor"] },
                beforeEnter: authGuard
            },

            {
                path: 'reports/turnstile/detail-turnstile-event',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/turnstile/detail-turnstile-event/DetailTurnStileEvent.vue'),
                name: 'Detail Turnstile Event',
                //meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/turnstile/detail-turnstile-error',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/turnstile/detail-turnstile-error/DetailTurnStileError.vue'),
                name: 'Detail Turnstile Error',
                //meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/turnstile/summary-turnstile-event',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/turnstile/summary-turnstile-event/SummaryTurnStileEvent.vue'),
                name: 'Summary Turnstile Event',
                //meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
            {
                path: 'reports/turnstile/summary-turnstile-error',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/turnstile/summary-turnstile-error/SummaryTurnStileError.vue'),
                name: 'Summary Turnstile Error',
                //meta: { authorize: ["Admin", "Finance"] },
                beforeEnter: authGuard
            },
        ]
    },
    {
        path: '/callback',
        component: () => import(/* webpackChunkName: "reports" */ '@/views/AuthoCallBack.vue'),
        name: 'Auth0CallBack',
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to: any, from: any, next: any) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const { authorize } = to.meta;
    let role = localStorage.getItem('role');

    if (authorize) {
        if (!role) {
            // not logged in so redirect to login page with the return url
            //return next({ path: "/login", query: { returnUrl: to.path } });
            return 'unauthorized';
        }

        // check if route is restricted by role
        if (authorize.length && !authorize.includes(role)) {
            // role not authorised so redirect to home page
            return next({ path: '/' });
        }
    }

    next();
});

export default router;
