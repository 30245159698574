import {
  DxDataGrid,
  DxColumn,
  DxColumnChooser,
  DxColumnFixing,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxSearchPanel,
  DxScrolling,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxPager,
  DxPaging,
  DxExport,
  DxStateStoring
} from 'devextreme-vue/data-grid';
import {
  DxDateBox,
  DxTextBox,
  DxLoadPanel,
  DxSelectBox,
  DxTagBox
} from 'devextreme-vue';
import DxList from 'devextreme-vue/list';
const devextreme = {
  install(Vue: any) {
    Vue.component('DxDataGrid', DxDataGrid);
    Vue.component('DxColumn', DxColumn);
    Vue.component('DxColumnChooser', DxColumnChooser);
    Vue.component('DxColumnFixing', DxColumnFixing);
    Vue.component('DxEditing', DxEditing);
    Vue.component('DxFilterRow', DxFilterRow);
    Vue.component('DxHeaderFilter', DxHeaderFilter);
    Vue.component('DxGroupPanel', DxGroupPanel);
    Vue.component('DxGrouping', DxGrouping);
    Vue.component('DxSearchPanel', DxSearchPanel);
    Vue.component('DxScrolling', DxScrolling);
    Vue.component('DxSummary', DxSummary);
    Vue.component('DxLookup', DxLookup);
    Vue.component('DxTotalItem', DxTotalItem);
    Vue.component('DxGroupItem', DxGroupItem);
    Vue.component('DxMasterDetail', DxMasterDetail);
    Vue.component('DxStringLengthRule', DxStringLengthRule);
    Vue.component('DxRequiredRule', DxRequiredRule);
    Vue.component('DxRangeRule', DxRangeRule);
    Vue.component('DxValueFormat', DxValueFormat);
    Vue.component('DxPager', DxPager);
    Vue.component('DxPaging', DxPaging);
    Vue.component('DxStateStoring', DxStateStoring);
    Vue.component('DxDateBox', DxDateBox);
    Vue.component('DxTextBox', DxTextBox);
    Vue.component('DxTagBox', DxTagBox);
    Vue.component('DxSelectBox', DxSelectBox);
    Vue.component('DxLoadPanel', DxLoadPanel);
    Vue.component('DxExport', DxExport);
    Vue.component('DxList', DxList);
  }
};

export default devextreme;
