
import Vue from 'vue';
import Component from 'vue-class-component';
import router from './router';
import { AxiosClient } from '@/client/axios';

@Component({
  name: 'App',
})
export default class App extends Vue {
  private client: AxiosClient = new AxiosClient();

  private getRole(role: any) {
    if (
      Array.isArray(role) ||
      (typeof role === 'object' && role !== null) ||
      typeof role === 'number' ||
      typeof role === 'string' ||
      typeof role === 'function'
    ) {
      return role.toString();
    }
    return undefined;
  }

  mounted() {
    const role: string | undefined = this.getRole(this.$route.query.role);
    if (role) {
      if (this.$route.query.role !== undefined) {
        localStorage.setItem('role', role);
      }
      sessionStorage.setItem('role', role);
      location.replace('/home');
    }
  }
}
