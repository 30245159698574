
import Vue from 'vue';
import Component from 'vue-class-component';
import AppModule from '@/store/modules/app';
import Sidebar from './Sidebar.vue';
import Footer from './Footer.vue';
import UserProfile from './UserProfile.vue';
import { getModule } from 'vuex-module-decorators';

@Component({
  name: 'Shell',
  components: {
    'app-footer': Footer,
    'app-sidebar': Sidebar,
    'user-profile': UserProfile,
  },
})
export default class Test extends Vue {
  public appModule = getModule(AppModule, this.$store);

  drawer: any = null;
  appModuleBiff: any = { environment: { name: 'STAGING' } };

  private stagingClass: string = 'blue darken-3';
  private developmentClass: string = 'red darken-3';

  private get iconStyle() {
    return !this.appModule.miniVariant
      ? 'mdi-arrow-expand-left'
      : 'mdi-arrow-expand-right';
  }
}
