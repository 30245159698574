import _Vue from 'vue'; // <-- notice the changed import
import axios from 'axios';

function AxiosPlugin(Vue: typeof _Vue, options?: any): void {
  Vue.prototype.$http = axios;
}

export default AxiosPlugin;

// https://www.mistergoodcat.com/post/vuejs-plugins-with-typescript

// import _Vue from "vue";
// import Axios from "axios";

// // export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;
// export function AxiosPlugin<AxiosPlugOptions>(Vue: typeof _Vue, options?: AxiosPluginOptions): void {
//     // do stuff with options
//     Vue.prototype.$http = Axios;
// }

// export class AxiosPluginOptions {
//     // add stuff
// }
