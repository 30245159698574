import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import devextreme from '@/plugins/devextreme';
import axios from '@/plugins/axios';
import { authService } from '@/views/auth0/Auth0ServiceInst';

Vue.use(devextreme);
Vue.use(axios);

Vue.config.productionTip = false;
Vue.prototype.$authService = authService;
new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');
