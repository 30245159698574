var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-footer",
    {
      staticClass: "white darken-2",
      attrs: { id: "footer", app: "", inset: "", height: "53" }
    },
    [
      _c("v-col", { staticClass: "text-right py-1" }, [
        _c("div", { staticClass: "caption font-weight-bold" }, [
          _c("span", [_vm._v("Powered by See It All ")]),
          _c("span", [_vm._v("© " + _vm._s(_vm.todayYear))])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }