export interface IAuth0Config {
  domain: string;
  clientId: string;
}

export class Auth0Config implements IAuth0Config {
  public domain: string = '';
  public clientId: string = '';

  constructor() {
    this.domain = process.env.VUE_APP_DOMAIN;
    this.clientId = process.env.VUE_APP_CLIENT_ID;
  }
}
