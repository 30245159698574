import { authService } from '@/views/auth0/Auth0ServiceInst';

export async function authGuard(to: any, from: any, next: any) {
    //let loginStatus: any;
    let loginStatus = sessionStorage.getItem('IsLoggedIn');
    if (loginStatus == 'false' || loginStatus == null) {
        await authService.login();
    } else {
        next();
    }
    // next();
}
