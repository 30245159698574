var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    { attrs: { id: "inspire" } },
    [
      _vm.appModule.environment.name !== "PRODUCTION"
        ? _c(
            "v-system-bar",
            {
              class: [
                _vm.appModule.environment.name === "UAT"
                  ? _vm.developmentClass
                  : _vm.stagingClass
              ],
              attrs: { app: "" }
            },
            [
              _c(
                "span",
                { staticClass: "white--text", attrs: { id: "system_bar" } },
                [
                  _vm._v(
                    _vm._s(`ENVIRONMENT : ${_vm.appModule.environment.name}`)
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _c(
        "v-app-bar",
        { attrs: { app: "", "clipped-right": "", color: "white" } },
        [
          _c("v-app-bar-nav-icon", {
            attrs: { "data-cy": "sideBarBtn" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                _vm.drawer = !_vm.drawer
              }
            }
          }),
          _vm.$vuetify.breakpoint.mdAndDown || _vm.appModule.miniVariant
            ? _c("img", {
                attrs: {
                  alt: "Vue logo",
                  src: "/img/sia-logo.png",
                  height: "45px"
                }
              })
            : _vm._e(),
          _c("v-spacer"),
          _c("user-profile")
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            app: "",
            color: "grey lighten-4",
            "mini-variant": _vm.appModule.miniVariant
          },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          !_vm.appModule.miniVariant
            ? _c("v-app-bar", [
                _c("img", {
                  attrs: {
                    alt: "Vue logo",
                    src: "/img/sia-logo.png",
                    height: "45px"
                  }
                })
              ])
            : _vm._e(),
          _c("app-sidebar"),
          _c(
            "div",
            { attrs: { slot: "append" }, slot: "append" },
            [
              _c("v-divider"),
              _c(
                "div",
                { staticClass: "px-4 py-2 d-flex" },
                [
                  !_vm.appModule.miniVariant
                    ? _c("v-btn", { attrs: { disabled: "", text: "" } }, [
                        _vm._v("v0.0.1")
                      ])
                    : _vm._e(),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.appModule.toggleSidebarVariant()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v(" " + _vm._s(_vm.iconStyle) + " ")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-main", [_c("router-view")], 1),
      _c("app-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }