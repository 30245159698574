
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'Footer',
  components: {},
})
export default class Footer extends Vue {
  private get todayYear() {
    return new Date().getFullYear();
  }
}
