
import { Vue, Component, Prop } from 'vue-property-decorator';
import { authService } from '@/views/auth0/Auth0ServiceInst';
import { Profile } from '@/views/auth0/UserProfile';
import { Method } from '@babel/types';

@Component({ name: 'UserProfile' })
export default class UserProfile extends Vue {
  @Prop([String]) userName: string | undefined;
  @Prop([String]) profilePicture: string | undefined;

  get userNameComputed() {
    let profileJson: any;
    profileJson = sessionStorage.getItem('profile');
    if (profileJson) {
      let userProfile: Profile = JSON.parse(profileJson);
      return userProfile.name;
    }
    return '';
  }

  get profilePictureComputed() {
    let profileJson: any;
    profileJson = sessionStorage.getItem('profile');
    if (profileJson) {
      let userProfile: Profile = JSON.parse(profileJson);
      return userProfile.picture;
    }
    return '';
  }

  public onLogOut() {
    sessionStorage.removeItem('IsLoggedIn');
    sessionStorage.removeItem('profile');
    sessionStorage.clear();
    authService.logout();
  }
}
