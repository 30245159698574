import Vue from 'vue';
import Vuex from 'vuex';
import AppModule, { IAppState } from './modules/app';
import AuthModule, { IAuthState } from './modules/auth';

Vue.use(Vuex);

export interface IRootState {
  app: IAppState;
  auth: IAuthState;
}

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { app: AppModule, auth: AuthModule }
});
