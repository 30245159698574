var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-list",
    { attrs: { shaped: "", dense: "", nav: "" } },
    [
      _c(
        "v-list-group",
        {
          attrs: { "prepend-icon": "mdi-chart-bar", value: "true" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function() {
                return [_c("v-list-item-title", [_vm._v("Reports")])]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.showFinanceReports
            ? _c(
                "v-list-group",
                {
                  attrs: { "sub-group": "", "no-action": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function() {
                          return [
                            _c(
                              "v-list-item-content",
                              [_c("v-list-item-title", [_vm._v("Finance")])],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    570780000
                  )
                },
                _vm._l(_vm.financeReports, function(r, i) {
                  return _c(
                    "v-list-item",
                    {
                      key: i,
                      staticClass: "pl-12",
                      attrs: { link: "", to: r[1] }
                    },
                    [
                      _c("v-list-item-title", {
                        domProps: { textContent: _vm._s(r[0]) }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _vm.showMarketingReports
            ? _c(
                "v-list-group",
                {
                  attrs: { "sub-group": "", "no-action": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function() {
                          return [
                            _c(
                              "v-list-item-content",
                              [_c("v-list-item-title", [_vm._v("Marketing")])],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    1141715468
                  )
                },
                _vm._l(_vm.marketingReports, function(r, i) {
                  return _c(
                    "v-list-item",
                    {
                      key: i,
                      staticClass: "pl-12",
                      attrs: { link: "", to: r[1] }
                    },
                    [
                      _c("v-list-item-title", {
                        domProps: { textContent: _vm._s(r[0]) }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _vm.showOperationsReports
            ? _c(
                "v-list-group",
                {
                  attrs: { "sub-group": "", "no-action": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function() {
                          return [
                            _c(
                              "v-list-item-content",
                              [_c("v-list-item-title", [_vm._v("Operations")])],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2846977582
                  )
                },
                _vm._l(_vm.operationsReports, function(r, i) {
                  return _c(
                    "v-list-item",
                    {
                      key: i,
                      staticClass: "pl-12",
                      attrs: { link: "", to: r[1] }
                    },
                    [
                      _c("v-list-item-title", {
                        domProps: { textContent: _vm._s(r[0]) }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _vm.showTurnstileReports
            ? _c(
                "v-list-group",
                {
                  attrs: { "sub-group": "", "no-action": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function() {
                          return [
                            _c(
                              "v-list-item-content",
                              [_c("v-list-item-title", [_vm._v("Turnstile")])],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2016833074
                  )
                },
                _vm._l(_vm.turnstileReports, function(r, i) {
                  return _c(
                    "v-list-item",
                    {
                      key: i,
                      staticClass: "pl-12",
                      attrs: { link: "", to: r[1] }
                    },
                    [
                      _c("v-list-item-title", {
                        domProps: { textContent: _vm._s(r[0]) }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-list-item",
        [
          _c("v-list-item-icon", [_c("v-icon", [_vm._v("mdi-settings")])], 1),
          _c("v-list-item-title", [_vm._v("Admin")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }