// Admin Reports & Finance reports
let admin_reports: any = {
  financeReports: [
    ['Sales Summary', '/reports/finance/sales-summary'],
    ['Sales Detail', '/reports/finance/sales-detail'],
    ['Sales Redeemed Summary', '/reports/finance/sales-redeemed-summary'],
    ['Sales Redeemed Detail', '/reports/finance/sales-redeemed-detail'],
    ['Tender Summary', '/reports/finance/tender-summary'],
    ['Tender Detail', '/reports/finance/tender-detail'],
    ['City Pass Detail', '/reports/finance/city-pass-detail'],
    // Removed as API endpoint is absent
    // ["Kiosk Upgrade Detail", "/reports/finance/kiosk-upgrade-detail"],
    ['Taxes Due Detail', '/reports/finance/Taxes-Due-Details'],
    ['Taxes Due Summary', '/reports/finance/taxes-due-summary'],
    [
      'Voucher Receivables Detail',
      '/reports/finance/voucher-receivables-detail',
    ],
    [
      'Voucher Receivables Summary',
      '/reports/finance/voucher-receivables-summary',
    ],
    [
      'Promotions Redeemed Detail',
      '/reports/finance/promotions-redeemed-detail',
    ],
    [
      'Promotions Redeemed Summary',
      '/reports/finance/promotions-redeemed-summary',
    ],
    ['Sales Not Redeemed Detail', '/reports/finance/sales-not-redeemed-detail'],
    [
      'Sales Not Redeemed Summary',
      '/reports/finance/sales-not-redeemed-summary',
    ],
    ['Receivable Due Detail', '/reports/finance/receivables-due-detail'],
    ['Receivable Due Summary', '/reports/finance/receivables-due-summary'],
    ['Payable Due Detail', '/reports/finance/payable-due-detail'],
    ['Payable Due Summary', '/reports/finance/payable-due-summary'],
    ['Promotions Sold Detail', '/reports/finance/promotions-sold-detail'],
    ['Promotions Sold Summary', '/reports/finance/promotions-sold-summary'],
    //["Category Sales Detail", "/reports/finance/category-sales-detail"],
    [
      'Transactions Detail By Ticket',
      '/reports/finance/transactions-detail-by-ticket',
    ],
  ],

  marketingReports: [
    ['Guest Contact Info', '/reports/marketing/guest-contact-info'],
    ['Manifest', '/reports/marketing/manifest'],
  ],

  operationsReports: [
    ['Access Summary', '/reports/operations/access-summary'],
    ['Bankout Tender Summary', '/reports/operations/bankout-tender-summary'],
    ['Bankout Tender Detail', '/reports/operations/bankout-tender-detail'],
    ['Inventory Calendar', '/reports/operations/inventory-calendar'],
    ['Advance Sales Detail', '/reports/operations/advance-sales-detail'],
    ['Advance Sales Summary', '/reports/operations/advance-sales-summary'],
    ['Redemption Detail', '/reports/operations/redemption-detail'],
    ['Redemption Summary', '/reports/operations/redemption-summary'],
    [
      'Validation Experience Summary',
      '/reports/operations/validation-experience-summary',
    ],
  ],

  turnstileReports: [
    ['Detail Turnstile Event', '/reports/turnstile/detail-turnstile-event'],
    ['Detail Turnstile Error', '/reports/turnstile/detail-turnstile-error'],
    ['Summary Turnstile Event', '/reports/turnstile/summary-turnstile-event'],
    ['Summary Turnstile Error', '/reports/turnstile/summary-turnstile-error'],
  ],
};

// Supervisor Reports
let supervisor_reports: any = {
  marketingReports: [
    ['Guest Contact Info', '/reports/marketing/guest-contact-info'],
    ['Manifest', '/reports/marketing/manifest'],
  ],

  operationsReports: [
    ['Access Summary', '/reports/operations/access-summary'],
    ['Bankout Tender Summary', '/reports/operations/bankout-tender-summary'],
    ['Bankout Tender Detail', '/reports/operations/bankout-tender-detail'],
    ['Inventory Calendar', '/reports/operations/inventory-calendar'],
    ['Advance Sales Detail', '/reports/operations/advance-sales-detail'],
    ['Advance Sales Summary', '/reports/operations/advance-sales-summary'],
    ['Redemption Detail', '/reports/operations/redemption-detail'],
    ['Redemption Summary', '/reports/operations/redemption-summary'],
    [
      'Validation Experience Summary',
      '/reports/operations/validation-experience-summary',
    ],
  ],
};

// Agent Reports & Group Sales Reports
let agent_reports: any = {
  marketingReports: [['Manifest', '/reports/marketing/manifest']],

  operationsReports: [
    ['Bankout Tender Summary', '/reports/operations/bankout-tender-summary'],
    ['Bankout Tender Detail', '/reports/operations/bankout-tender-detail'],
  ],
};

export default {
  admin_reports: admin_reports,
  supervisor_reports: supervisor_reports,
  agent_reports: agent_reports,
};
